<template>
	<div>
		<LoadingScreen v-if="loading" />
		<ContentHeader3 title="Tindakan" url="/tindakan" title2="Data Tindakan" url2="/data-tindakan" subTitle="Edit Data Tindakan" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="row justify-content-center">
				<div class="col">
					<div class="card">
						<div class="card-header row">
							<h3 class="card-title">Edit Data Tindakan</h3>
						</div>
						<div class="card-body row">
							<div class="col-lg-2"></div>
							<div class="col-lg-8">
								<form @submit.prevent="updateDataTindakan">
									<div class="px-lg-2 ">
										<div class="form-group">
											<label for="kode_tindakan">Kode</label>
											<input type="text" class="form-control" id="kode_tindakan" name="kode_tindakan" disabled="" v-model="tindakan.kode_tindakan">
										</div>
										<div class="form-group">
											<label for="nama">Nama <span class="text-danger">*</span></label>
											<input type="text" class="form-control" id="nama" required="" name="nama" placeholder="Nama Tindakan" autocomplete="off" v-model="tindakan.nama">
										</div>
										<div class="form-group">
											<label for="kategori">Type <span class="text-danger">*</span></label>
											<select class="custom-select form-control" v-model="tindakan.poli_id">
												<option selected hidden=""></option>
												<option v-for="(row, index) in poli" :value="row.id" :key="index">{{ row.nama }}</option>
											</select>
										</div>
										<!-- tambah cabang -->
										<div class="w-100 mb-3">
										<label for="cabang">Pilih Cabang<span class="text-danger" >*</span></label>
										<el-select id="cabang_id" v-model="tindakan.cabang_id" filterable placeholder="Pilih Cabang" class="w-100" :loading="loading_cabang">
											<el-option v-for="(row, index) in cabang" :key="index" :label="row.nama" :value="row.id" />
										</el-select>
										</div>
										<!-- tambah cabang -->
										<div class="form-group">
											<label for="kategori">Kategori <span class="text-danger">*</span></label>
											<select class="custom-select form-control" v-model="tindakan.kategori">
												<option selected hidden=""></option>
												<option value="tindakan">Tindakan</option>
												<option value="paket">Paket</option>
												<option value="jasa">Jasa</option>
											</select>
										</div>
										<div class="form-group">
											<label for="lama_tindakan">Durasi <span class="text-danger">*</span></label>
											<div class="input-group mb-3">
												<div class="input-group-prepend">
												<div type="button" class="btn btn-secondary">Menit</div>
												</div>
												<!-- /btn-group -->
												<input type="number" class="form-control" required="" id="lama_tindakan" name="lama_tindakan" placeholder="Durasi Tindakan" v-model="tindakan.lama_tindakan">
											</div>
										</div>
										<div class="form-group">
											<label for="lama_tindakan">Tarif <span class="text-danger">*</span></label>
											<div class="row">
												<div class="col">
													<span>Umum</span>
													<div class="input-group mb-3">
														<div class="input-group-prepend">
														<div type="button" class="btn btn-secondary">Rp.</div>
														</div>
														<!-- /btn-group -->
														<input class="form-control" type="number" required="" name="harga_pasien_baru" v-model="tindakan.harga_pasien_baru">
													</div>
												</div>
												<div class="col">
													<span>Member</span>
													<div class="input-group mb-3">
														<div class="input-group-prepend">
														<div type="button" class="btn btn-secondary">Rp.</div>
														</div>
														<!-- /btn-group -->
														<input class="form-control" type="number" required="" name="harga_pasien_member" v-model="tindakan.harga_pasien_member">
													</div>
												</div>
											</div>
										</div>
										<div class="form-group">
											<div class="d-flex justify-content-between align-items-center">
												<label>Tarif Per Layanan</label>
												<p class="btn btn-light" @click="tambahHargaLayanan()">Tambah</p>
											</div>
											<table class="table table-bordered table-hover mt-2">
												<thead>
													<tr>
														<th scope="col" class="text-sm">Layanan</th>
														<th scope="col" class="text-sm">Harga</th>
														<th scope="col" class="text-sm text-center">Aksi</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(row, index) in tindakan.harga_per_layanan" :key="index">
														<td>
															<el-select v-model="row.layanan_id" placeholder="Pilih Layanan" :loading="loading_layanan" filterable clearable class="w-100">
																<el-option v-for="(row, index) in dataLayanan" :key="index" :value="row.id" :label="row.nama"></el-option>
															</el-select>
														</td>
														<td>
															<input class="form-control" type="number" v-model="row.harga">
														</td>
														<td class="d-flex justify-content-center">
															<p class="mx-auto btn btn-outline-danger" @click="hapusHargaLayanan(index)">Hapus</p>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div class="form-group">
											<label for="modal">Modal <span class="text-danger">*</span></label>
											<div class="input-group mb-3">
												<div class="input-group-prepend">
												<div type="button" class="btn btn-secondary">Rp.</div>
												</div>
												<!-- /btn-group -->
												<input type="number" class="form-control" id="modal" required="" name="modal" placeholder="Modal" v-model="tindakan.modal">
											</div>
										</div>
										<div class="form-group">
											<label>Fee </label>
											<div class="row">
												<div class="col">
													<span for="fee_dokter">Fee Dokter <span class="text-danger">*</span></span>
													<div class="input-group mb-3">
														<div class="input-group-prepend">
														<div class="btn btn-secondary">{{ fee_dokter }}</div>
														</div>
														<!-- /btn-group -->
														<input type="number" class="form-control" id="fee_dokter" step="any" required="" name="fee_dokter" v-model="tindakan.fee_dokter" @keyup="feeForDokter()">
													</div>
												</div>
												<div class="col">
													<span for="fee_dokter">Fee Beautician <span class="text-danger">*</span></span>
													<div class="input-group mb-3">
														<div class="input-group-prepend">
														<div class="btn btn-secondary">{{ fee_beautician }}</div>
														</div>
														<!-- /btn-group -->
														<input type="number" class="form-control" id="fee_beautician" step="any" required="" name="fee_dokter" v-model="tindakan.fee_beautician" @keyup="feeForBeautician()">
													</div>
												</div>
												<div class="col">
													<span for="fee_dokter">Fee Sales <span class="text-danger">*</span></span>
													<div class="input-group mb-3">
														<div class="input-group-prepend">
														<div class="btn btn-secondary">{{ fee_sales }}</div>
														</div>
														<!-- /btn-group -->
														<input type="number" class="form-control" id="fee_sales" step="any" required="" name="fee_dokter" v-model="tindakan.fee_sales" @keyup="feeForSales()">
													</div>
												</div>
											</div>
										</div>
										<div class="form-group">
											<label for="point">Point</label>
											<input type="number" class="form-control" id="point" name="point" placeholder="Point" v-model="tindakan.point">
										</div>
										<button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
											<div>Submit</div>	
											<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
												<span class="sr-only">Loading...</span>
											</div>
										</button>
									</div>
									<!-- /.card-body -->
									</form>
							</div>
							<div class="col-lg-2"></div>
						</div>
					</div>
				</div>
			</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import ContentHeader3 from '@/components/ContentHeader3'
import LoadingScreen from '@/components/LoadingScreen'

import { ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import axios from 'axios'
import router from '@/router'
import Swal from 'sweetalert2'
import store from '@/store'

const route = useRoute()
const tindakan_id = route.params.id
const loading = ref(false)

// data
const user 			= computed(() => store.getters['auth/user'])
const poli	= computed(() => store.getters['poli/getPoli'])

// fetch data
store.dispatch('poli/fetchPoli')

const { tindakan, getTindakanWhereId, updateDataTindakan, feeForDokter, feeForBeautician, feeForSales, fee_dokter, fee_beautician, fee_sales } = useUpdateTindakan(tindakan_id, loading)
//cabang
const toogleCabang = ref(false)
// const cabang_select_value = ref('')
const cabang = ref([])
const loading_cabang = ref(true)
const getCabang = async () => {
    let response = await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)

    if (response.data.jenis == 'pusat') {
        toogleCabang.value = true
        let { data } = await axios.get(`api/cabang/getWhereCabang/${response.data.id}`)
    
        if (data != 'kosong') {
            cabang.value = data
            loading_cabang.value = false
        } else {
            cabang.value = []
            loading_cabang.value = false
        }
    }
}
// LAYANAN
	const dataLayanan = ref([])
	const loading_layanan = ref(true)
	const cek_data_layanan = ref(false)

	const getLayanan = async () => {
		await axios.get(`api/layanan/getWhereAktif`)
		.then((response) => {
			if (response.data == 'kosong') {
				loading_layanan.value = false
				cek_data_layanan.value = true
				dataLayanan.value = []
			} else {
				loading_layanan.value = false
				cek_data_layanan.value = false
				dataLayanan.value = response.data
			}
		})

	}
// END LAYANAN

const tambahHargaLayanan = () => {
	tindakan.value.harga_per_layanan.push({ layanan_id: null, harga: 0 })
}

const hapusHargaLayanan = (index) => {
	tindakan.value.harga_per_layanan.splice(index, 1)
}

onMounted(() => {
	getTindakanWhereId()
	getLayanan()
	getCabang()
})


function useUpdateTindakan (tindakan_id, loading) {
	const tindakan = ref([])
	const fee_dokter = ref('...')
	const fee_beautician = ref('...')
	const fee_sales = ref('...')

	const feeForDokter = () => {
		if (tindakan.value.fee_dokter == 0) {
			fee_dokter.value = '...'
		} else {
			if (tindakan.value.fee_dokter <= 100) {
				fee_dokter.value = 'Persen (%)'
			} else {
				fee_dokter.value = 'Rp.'
			}
		}
	}

	const feeForBeautician = () => {
		if (tindakan.value.fee_beautician == 0) {
			fee_beautician.value = '...'
		} else {
			if (tindakan.value.fee_beautician <= 100) {
				fee_beautician.value = 'Persen (%)'
			} else {
				fee_beautician.value = 'Rp.'
			}
		}
	}

	const feeForSales = () => {
		if (tindakan.value.fee_sales == 0) {
			fee_sales.value = '...'
		} else {
			if (tindakan.value.fee_sales <= 100) {
				fee_sales.value = 'Persen (%)'
			} else {
				fee_sales.value = 'Rp.'
			}
		}
	}
	
	const getTindakanWhereId = async () => {
		loading.value = true
		await axios.get(`api/tindakan/getWhereId/${tindakan_id}`)
		.then((response) => {
			tindakan.value = response.data
			tindakan.value.harga_per_layanan = tindakan.value.harga_per_layanan ? JSON.parse(tindakan.value.harga_per_layanan) : []
			tindakan.value.harga_per_layanan_convertion = null

			feeForDokter()
			feeForBeautician()
			feeForSales()
			loading.value = false
		})
	}

	const updateDataTindakan = () => {
		if (loading.value == false) {
			loading.value = true
			
			tindakan.value.harga_per_layanan_convertion = tindakan.value.harga_per_layanan ? JSON.stringify(tindakan.value.harga_per_layanan) : null
			axios.put(`api/tindakan/update/${tindakan_id}`, tindakan.value)
			.then((response) => {
				Swal.fire({
				  title: 'Berhasil!',
				  text: 'Berhasil Mengedit Data',
				  icon: 'success',
				  showConfirmButton: false,
				  timer: 1500
				})
	    		console.log(response.data)
	    		router.push('/data-tindakan')
	    	})
	    	.catch((error) => {
	    		Swal.fire({
				  title: 'Gagal!',
				  text: 'Gagal Mengedit Data',
				  icon: 'error',
				  confirmButtonText: 'Ok'
				})	
	    		console.log(error)
	    	})	
			.finally(() => {
				loading.value = false
			})
		}
	}

	return {
		tindakan,
		getTindakanWhereId,
		updateDataTindakan,
		fee_dokter,
		fee_beautician,
		fee_sales,
		feeForDokter,
		feeForBeautician,
		feeForSales,
	}
}


</script>


<style>
	
</style>